import * as React from 'react';
import MessageBox from '../../components/MessageBox';
import FeedbackModule from '../../components/FeedbackModule';
import * as classNames from '../../assets/css/pages/uninstall_success.module.css';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';

const CommerceExtUninstallPage = () => {
    const cookieValue = Cookies.get('ncid');
    const [ncidValue, setNcidValue] = useState(null);
    const [cookieLookupDidComplete, setCookieLookupDidComplete] = useState(false);
    const sanitizeCookieValue = () => {
        if (typeof window !== 'undefined' && !cookieValue) return null;
        // This code only runs in the browser. Should be called from useEffect or similar API.
        return DOMPurify.sanitize(cookieValue);            
    }

    useEffect(() => {
        setNcidValue(sanitizeCookieValue());
        setCookieLookupDidComplete(true);
    }, [cookieValue]); 

    const [isSubmitted, setIsSubmitted] = useState(false);
    const onSubmit = () => {
        setIsSubmitted(true);
    };

    return (
        <div className={classNames.container}>
            <MessageBox isSubmitted={isSubmitted} />
            <FeedbackModule onSubmit={onSubmit} isSubmitted={isSubmitted} ncid={ncidValue} onCookieLookupDidComplete={cookieLookupDidComplete} />
        </div>
    );
};

export default CommerceExtUninstallPage;
