// extracted by mini-css-extract-plugin
export var DesktopAndAfter = "only screen and (min-width: 1020px)";
export var button = "feedbackModule-module--button--344cf";
export var checkBox = "feedbackModule-module--checkBox--c799e";
export var checklistItem = "feedbackModule-module--checklistItem--7d0d3";
export var container = "feedbackModule-module--container--bcd52";
export var feedbackTextArea = "feedbackModule-module--feedbackTextArea--8dc93";
export var hiddenContainer = "feedbackModule-module--hiddenContainer--bf7c6";
export var intro = "feedbackModule-module--intro--626ad";
export var submitButton = "feedbackModule-module--submitButton--8659d";
export var submitButtonText = "feedbackModule-module--submitButtonText--99546";